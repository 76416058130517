<template>
  <div class="custom-radio-group-wrapper">
    <div class="title" v-if="title">{{ title }}</div>
    <el-radio-group
      v-model="label"
      @change="handleChange"
      :border="false"
      :class="{ 'title-group': !title }"
    >
      <el-radio :label="item.label" v-for="item in dataList" :key="item.label">
        {{ item.name }}
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
export default {
  name: 'CustomRadioGroup',
  emits: ['onChange'],
  props: {
    title: String,
    dataList: {
      type: Array,
      default: () => [
        {
          label: 'position',
          name: '区位'
        },
        {
          label: 'distance',
          name: '距离'
        }
      ]
    }
  },
  setup: (props, { emit }) => {
    const label = ref(props.dataList[0].label)

    watch(
      () => props.dataList,
      val => {
        label.value = val[0].label
      }
    )

    const handleChange = e => {
      emit('onChange', label.value)
    }
    return { label, handleChange }
  }
}
</script>

<style lang="less" scoped>
@textColor: #fff;
@bgc: #13131a;
.custom-radio-group-wrapper {
  // position: absolute;
  // right: 38px;
  // bottom: 55px;

  .title {
    background-color: @bgc;
    color: @textColor;
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    padding: 15px 0 10px 10px;
  }

  .title-group {
    padding: 20px 15px !important;
    border-radius: 10px !important;
  }

  .el-radio-group {
    color: #fff;
    padding: 10px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    background-color: @bgc;
    border-radius: 0 0 10px 10px;
    border: none;

    :deep(.el-radio__input.is-checked + .el-radio__label) {
      color: #fff;
    }

    .el-radio {
      margin-right: 10px;
      margin-bottom: 16px;
      color: #fff;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
