<template>
  <div class="street-list-wrapper">
    <TabsListLayout
      :tabList="tabList"
      :showDetail="showDetail"
      :labelStyle="{ padding: '0 20px', justifyContent: 'flex-start' }"
    >
      <template #filter>
        <ListSort :dataList="sortData" @onSort="handleSort" />
      </template>

      <template #list>
        <ListView :dataList="buildingList" v-slot="{ item, index }">
          <BildingListViewItem
            :dataObj="item"
            :index="index"
            :rightDataList="rightDataList"
            @click="handleItemClick(item)"
          />
        </ListView>
      </template>

      <template #detail>
        <BuildingDetail @onClose="hanldeCloseBtn" />
      </template>
    </TabsListLayout>
  </div>
</template>

<script>
import TabsListLayout from '@/layout/TabsListLayout'
import ListSort from '@/components/ListSort'
import ListView from '@/components/ListView'
import BildingListViewItem from '@/components/ListView/BildingListViewItem'
// import StreetDetail from '@/views/buildingResources/components/StreetDetail'
import BuildingDetail from '@/components/ListView/BuildingDetail'

import { useStore } from 'vuex'
import { ref, computed, watch } from 'vue'
import { SET_CURRENT_BUILDING_ID } from '@/store/mutation-types'
const tabList = [
  {
    label: '楼宇列表',
    name: 'BuildingDetail',
    type: 2
  }
]
export default {
  name: 'RightPartBT',
  components: {
    TabsListLayout,
    ListSort,
    ListView,
    BildingListViewItem,
    // StreetDetail
    BuildingDetail
  },
  props: {
    rightDataList: Array,
    sortData: {
      type: Array,
      default: () => [
        {
          label: '楼宇名称',
          sort: -1,
          key: 'name',
          setName: 'data'
        },
        {
          label: '注册企业数',
          sort: -1,
          key: 'regCpyCount',
          setName: 'stats'
        },
        {
          label: '总税收',
          sort: -1,
          key: 'regLastYearTax',
          setName: 'stats'
        }
      ]
    }
  },
  setup: props => {
    // const buildingList = ref(buildingDataList.listData)
    const showDetail = ref(false)
    // const buildingListAll = ref([])
    const store = useStore()
    const currentStreetId = computed(() => store.state.common.currentStreetId)
    const buildingList = computed(() => store.state.common.buildingList)
    const mapBuildingGeojsonParams = computed(() => store.state.common.mapBuildingGeojsonParams)
    // 楼宇标签
    const currentBuildingTag = computed(() => store.state.buildingResources.currentBuildingTag)
    // 六大产业
    const currentIndustryType = computed(() => store.state.industrialEconomy.currentIndustry)
    const options = {}

    const handleItemClick = item => {
      store.commit(SET_CURRENT_BUILDING_ID, item.id)
      showDetail.value = true
    }

    const hanldeCloseBtn = () => {
      showDetail.value = false
    }

    const handleSort = item => {
      const { key, sort, setName } = item
      if (sort < 0) {
        buildingList.value.sort((a, b) =>
          typeof b[setName][key] === 'string'
            ? b[setName][key].localeCompare(a[setName][key])
            : b[setName][key] - a[setName][key]
        )
      } else {
        buildingList.value.sort((a, b) => {
          return typeof b[setName][key] === 'string'
            ? a[setName][key].localeCompare(b[setName][key])
            : a[setName][key] - b[setName][key]
        })
      }
    }

    const fetchData = () => {
      const { streetId, buildingType, currentIndustryType } = options

      const params = {
        conditions: {
          'data.name': { $exists: 1 },
          'data.buildingTypeLabel': { $in: ['商务楼宇'] }
        }
      }

      if (streetId) {
        params.conditions['data.street'] = `NumberLong(${streetId})`
      }

      if (buildingType) {
        params.conditions['data.buildingType'] = buildingType
      }

      if (currentIndustryType) {
        params.conditions['data.industryType'] = currentIndustryType
      }

      store.dispatch('getBuildingList', params)
    }

    // const getBuildingList = async params => {
    //   buildingListAll.value = await store.dispatch('getBuildingList', params)
    // }

    watch([currentBuildingTag, currentIndustryType], ([tag, type]) => {
      if (tag) {
        options.buildingType = { $in: [`${tag}`] }
      } else {
        delete options.buildingType
      }

      if (type && type !== '0') {
        options.currentIndustryType = { $in: [type] }
      } else {
        delete options.currentIndustryType
      }

      fetchData(options)
    })

    watch(
      mapBuildingGeojsonParams,
      () => {
        fetchData()
      },
      { immediate: true }
    )

    watch(currentStreetId, streetId => {
      options.streetId = streetId
      fetchData(options)
    })

    return {
      tabList,
      buildingList,
      handleSort,
      showDetail,
      handleItemClick,
      hanldeCloseBtn
    }
  }
}
</script>

<style lang="less" scoped>
@textColor: #fafafb;
@titleBgc: #1b1c1c;

.street-list-wrapper {
  height: 100%;
}

.detail-box {
  height: 100%;

  .title-close {
    height: 75px;
    color: @textColor;
    // background-color: @titleBgc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: 24px;
    padding-left: 20px;

    .close {
      width: 20px;
      cursor: pointer;
    }
  }

  .detail-content {
    height: calc(100% - 75px);
  }
}
</style>
