<template>
  <div class="list-view-wrapper">
    <div class="list-item" v-for="(item, index) in dataList" :key="item.id">
      <slot :item="item" :index="index"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListView',
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  },
  setup: () => {
    return {}
  }
}
</script>

<style lang="less" scoped>
@hoverColor: lighten(#1a1a1a, 10%);

.list-view-wrapper {
  height: 100%;
  // overflow-y: auto;
  // overflow-x: hidden;

  // &::-webkit-scrollbar {
  //   position: absolute;
  //   right: -10px;
  //   width: 8px;
  //   height: 14px;
  //   // display: none;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #1e75ff;
  //   -webkit-background-clip: padding-box;
  //   background-clip: padding-box;
  //   min-height: 28px;
  //   border-radius: 8px;
  // }

  // &::-webkit-scrollbar-track-piece {
  //   background-color: #161e31;
  // }

  .list-item {
    cursor: pointer;
    margin: 0 -10px;

    &:hover {
      background-color: @hoverColor;
    }
  }
}
</style>
