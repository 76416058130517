<template>
  <div
    ref="listItem"
    class="street-list-view-item-wrapper"
    :class="{ active: mapBuildingName === dataObj.id }"
    @mouseover="handleMouseOver(dataObj.id)"
  >
    <div class="left">
      <div class="index">{{ index + 1 }}</div>
      <!-- <div class="index">{{ dataObj.data.name && dataObj.data.name.substr(0, 1) }}</div> -->
      <div class="name">{{ dataObj.data.name }}</div>
    </div>
    <div class="right">
      <LabelValueUnit
        v-for="(item, index) in rightDataList"
        :key="index"
        :label="item.label"
        :value="
          item.format
            ? formatTax(dataObj[item.valueKey1][item.valueKey2])
            : dataObj[item.valueKey1][item.valueKey2] || 0
        "
        :unit="item.unit"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, watch, computed } from 'vue'
import LabelValueUnit from '@/components/ListView/LabelValueUnit.vue'
import { SET_HOVER_BUILDING_NAME, SET_MAP_BUILDING_NAME } from '@/store/mutation-types'
import { formatTax } from '@/utils/util'
export default {
  name: 'ListViewItem',
  components: { LabelValueUnit },
  props: {
    dataObj: Object,
    index: Number,
    rightDataList: {
      type: Array,
      default: () => [
        {
          label: '注册企业数',
          valueKey1: 'stats',
          valueKey2: 'regCpyCount',
          unit: '家',
          format: false
        },
        {
          label: '总税收',
          valueKey1: 'stats',
          valueKey2: 'regLastYearTax',
          unit: '万元',
          format: true
        }
      ]
    }
  },
  setup: props => {
    const listItem = ref()
    const store = useStore()
    const hoverBuildingName = computed(() => store.state.common.hoverBuildingName)
    const mapBuildingName = computed(() => store.state.common.mapBuildingName)

    const handleMouseOver = buildingId => {
      store.commit(SET_HOVER_BUILDING_NAME, buildingId)
      store.commit(SET_MAP_BUILDING_NAME, '')
    }

    watch(mapBuildingName, buildingId => {
      if (buildingId === props.dataObj.id) {
        listItem.value.scrollIntoView()
      }
    })
    return { hoverBuildingName, mapBuildingName, handleMouseOver, listItem, formatTax }
  }
}
</script>

<style lang="less" scoped>
@titleColor: #fafafb;
@infoColor: #fff;
@keyInfoColor: #0091ff;
@leftBgc: #1e75ff;
@hoverColor: lighten(#1a1a1a, 10%);

.street-list-view-item-wrapper {
  padding: 18px 10px;
  border-top: 1px solid #070b15;
  cursor: pointer;
  height: 90px;

  display: flex;
  align-items: center;
  border-top: 1px solid #070b15;
  border-bottom: 1px solid #070b15;

  .left {
    flex: 1;
    padding-right: 10px;
    display: flex;
    align-items: center;

    .index {
      width: 38px;
      height: 38px;
      background-color: #1e75ff;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      border-radius: 10px;
      margin-right: 18px;
    }

    .name {
      flex: 1;
      color: #fafafb;
      line-height: 1.2;
    }
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
}

.active {
  background-color: @hoverColor;
}
</style>
