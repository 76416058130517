<template>
  <div class="sub-menu-wrapper">
    <div class="select-box">
      <el-select v-model="currentStreet" placeholder="选择街道" @change="handleChange">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div
      class="menu-item"
      v-for="(item, index) in menuList"
      :key="item.label"
      :class="{ active: index === currentIndex }"
      @click="handleMenuClick(item, index)"
    >
      <!-- <Icon :name="item.iconName" class="item-icon" /> -->
      <div class="item-name">{{ item.label }}</div>
    </div>
  </div>
</template>

<script>
// import Icon from '@/components/IconSvg'
import { ref, onBeforeUnmount } from 'vue'
// import { streetNameId as options } from '@/utils/locaData'
import { useStore } from 'vuex'
import { SET_CURRENT_STREET_ID, SET_CURRENT_BUILDING_TAG } from '@/store/mutation-types'
import { getStreetList } from '@/api/list'
export default {
  name: 'SubMenu',
  components: {},
  emits: ['onChange'],
  props: {
    dataList: {
      type: Array,
      default: () => [
        {
          iconName: 'quanbu',
          label: '全部楼宇',
          key: 0
        },
        {
          iconName: 'chuzu',
          label: '亿元楼宇',
          key: 1
        },
        {
          iconName: 'chushou',
          label: '重点楼宇',
          key: 2
        },
        {
          iconName: 'chushou',
          label: '特色楼宇',
          key: 3
        }
      ]
    },
    defalutIndex: {
      type: Number,
      default: 0
    }
  },
  setup: (props, { emit }) => {
    const store = useStore()
    const currentIndex = ref(props.defalutIndex)
    const currentStreet = ref('')
    const options = ref([])

    getStreetList().then(res => {
      const list = res.data
      const o = list.map(d => ({
        label: d.data.name,
        value: d.id
      }))

      options.value = [
        {
          label: '全部街道',
          value: ''
        },
        ...o
      ]
    })

    const handleMenuClick = (menu, index) => {
      currentIndex.value = index
      emit('onChange', menu)
      store.commit(SET_CURRENT_BUILDING_TAG, menu.key)
    }

    const handleChange = streetId => {
      store.commit(SET_CURRENT_STREET_ID, streetId)
    }

    onBeforeUnmount(() => {
      store.commit(SET_CURRENT_BUILDING_TAG, 0)
      store.commit(SET_CURRENT_STREET_ID, '')
    })

    return {
      options,
      currentIndex,
      currentStreet,
      handleMenuClick,
      menuList: props.dataList,
      handleChange
    }
  }
}
</script>

<style lang="less" scoped>
@menuColor: #b5b5be;
@menuActiveColor: #1e75ff;
@bgc: #1c1c24;

.sub-menu-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @bgc;
  border-radius: 10px;
  overflow: hidden;

  .select-box {
    width: 150px;
  }

  .menu-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100px;
    height: 42px;
    // margin: 0 50px;
    cursor: pointer;
    color: @menuColor;
    transition: all 0.5s;

    .item-name {
      font-size: 16px;
    }

    .item-icon {
      margin-bottom: 10px;
      width: 20px;
      height: 20px;
    }

    &:last-child {
      border-radius: 10px;
    }
  }

  .active {
    // background: url('../../assets/images/menu_bg.png');
    transition: all 0.5s;
    color: #fff;
    background-color: @menuActiveColor;
    border-radius: 10px;
  }
}
</style>
