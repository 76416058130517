import { watch, computed } from 'vue'
import store from '@/store'
export default (map, AMap, geoJson, visible = false) => {
  const polygonMap = {}
  const shiNanQu = new AMap.GeoJSON({
    geoJSON: geoJson,
    // 还可以自定义getMarker和getPolyline
    getPolygon: (geojson, lnglats) => {
      const polygon = new AMap.Polygon({
        path: lnglats,
        strokeWeight: 0.8,
        fillOpacity: 0.05,
        fillColor: '#0091ff',
        strokeColor: '#0091ff',
        strokeStyle: 'solid', // 轮廓线样式，实线:solid 虚线:dashed
        bubble: true
        // cursor: 'pointer'
      })

      polygonMap[geojson.properties.streetId] = polygon
      return polygon
    },
    getMarker: (geojson, lnglats) => {
      // console.log(geojson, lnglats)
      const { streetName } = geojson.properties
      const text = new AMap.Text({
        position: lnglats,
        anchor: 'bottom-center',
        text: streetName,
        style: {
          'background-color': 'transparent',
          border: 'transparent',
          'font-size': '14px',
          'pointer-events': 'none'
        }
      })

      return text
    }
  })

  shiNanQu.setOptions({
    zooms: [12, 20],
    visible
  })

  map.add(shiNanQu)

  const currentStreetId = computed(() => store.state.common.currentStreetId)
  watch(currentStreetId, streetId => {
    const currentPolygon = polygonMap[streetId]

    Object.keys(polygonMap).forEach(key => {
      if (!streetId || key === streetId) {
        polygonMap[key].show()
      } else {
        polygonMap[key].hide()
      }
    })
    map.setFitView(currentPolygon)
  })

  return shiNanQu
}
